<template>
  <v-layout column class="tool-box__auto-music">
    <v-flex>
      <h2 class="tool-box__auto-music__header-title">Meeting Rating</h2>
      <v-layout column>
        <v-flex shrink>
          <v-switch v-model="disableAutoMusic" class="switch">
            <template v-slot:label>
              <span class="v-label-white"> Turn on stars</span>
            </template>
          </v-switch>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  name: "MeetingRating",
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["game", "gameID"]),
    disableAutoMusic: {
      get() {
        return this.game?.meetingRating ?? false
      },
      async set(value) {
        await this.updateGameAny({
          theKey: this.gameID,
          meetingRating: value
        })
      }
    }
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"])
  }
}
</script>

<style lang="scss">
.tool-box__auto-music {
  margin: 4px;
  .switch {
    padding: 0px;
    margin: 0px;
    margin-top: -5px;
  }
}
</style>
